import {nodeFromString} from "../tools/NodeFromString";
import axios from "axios";

document.addEventListener('DOMContentLoaded', () => {
    let contactEmails = document.querySelector('.contact-emails-container')

    if (contactEmails) {
        let container = contactEmails.querySelector('.contact-emails-list'),
            addButton = contactEmails.querySelector('.contact-emails-create'),
            currentItemsCount = container.querySelectorAll('.contact-email').length

        function initItem(item) {
            item.querySelector('.contact-email-delete')?.addEventListener('click', () => item.remove())
        }

        function createItem(index) {
            axios.get('/admin/contacts/email-template', {params: {index: index}})
                .then(result => {
                    let response = result.data
                    if (response.success) {
                        let item = nodeFromString(response.data)
                        initItem(item)
                        container.append(item)
                    }
                })
        }

        container.querySelectorAll('.contact-email').forEach(item => initItem(item))
        addButton.addEventListener('click', () => createItem(currentItemsCount++))
    }

    let contactPhones = document.querySelector('.contact-phones-container')

    if (contactPhones) {
        let container = contactPhones.querySelector('.contact-phones-list'),
            addButton = contactPhones.querySelector('.contact-phones-create'),
            currentItemsCount = container.querySelectorAll('.contact-phone').length

        function initItemPhone(item) {
            item.querySelector('.contact-phone-delete')?.addEventListener('click', () => item.remove())
        }

        function createItemPhone(index) {
            axios.get('/admin/contacts/phone-template', {params: {index: index}})
                .then(result => {
                    let response = result.data
                    if (response.success) {
                        let item = nodeFromString(response.data)
                        initItemPhone(item)
                        container.append(item)
                    }
                })
        }

        container.querySelectorAll('.contact-phone').forEach(item => initItemPhone(item))
        addButton.addEventListener('click', () => createItemPhone(currentItemsCount++))
    }

    let contactAddresses = document.querySelector('.contact-addresses-container')

    if (contactAddresses) {
        let container = contactAddresses.querySelector('.contact-addresses-list'),
            addButton = contactAddresses.querySelector('.contact-addresses-create'),
            currentItemsCount = container.querySelectorAll('.contact-address').length

        function initItemAddress(item) {
            item.querySelector('.contact-address-delete')?.addEventListener('click', () => item.remove())
        }

        function createItemAddress(index) {
            axios.get('/admin/contacts/address-template', {params: {index: index}})
                .then(result => {
                    let response = result.data
                    if (response.success) {
                        let item = nodeFromString(response.data)
                        initItemAddress(item)
                        container.append(item)
                    }
                })
        }

        container.querySelectorAll('.contact-address').forEach(item => initItemAddress(item))
        addButton.addEventListener('click', () => createItemAddress(currentItemsCount++))
    }
})
